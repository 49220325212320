<!--
  Component which the user can tap to attempt to claim a token they previously purchased.
-->
<template>
  <div class="claim-card token-card" :class="{ claimed: claimed }">
    <div class="claim-card__media">
      <img :src="tokenImage" :alt="claimData.id" />
      <div v-if="claiming" class="overlay"></div>
      <div class="claim-card__content">
        <p v-if="claiming" class="processing content">
          This token is being minted. You can leave this screen while you wait.
        </p>
      </div>
    </div>
    <h4 class="claim-title" :class="{ break: breakTitle }">{{ claimTitle }}</h4>
    <button class="standard claim-button" :disabled="claiming" @click="attemptClaim(claimData)">
      {{ !claiming ? 'Mint' : 'Minting...' }}
    </button>
    <v-checkbox v-if="userRole && userRole === 'admin'" v-model="mintForUser" label="Mint for another user?" />
    <NearAddressInput
      v-if="mintForUser"
      title="Recipient"
      label="NEAR Address"
      v-model="recipient"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ClaimCard',
  async created () {
    // console.log(this.claimData)
  },
  props: {
    /**
     * The claim data used to populate the UI and trigger the claim attempt.
     */
    claimData: {
      type: Object,
      default: () => {}
    },
    claimed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      claiming: false,
      agreed: false,
      typeData: {},
      mintForUser: false,
      recipient: ''
    }
  },
  computed: {
    ...mapGetters([
      'userRole'
    ]),
    claimTitle () {
      return this.claimData.name || this.claimData.type
    },
    breakTitle () {
      return this.wordBreakCheck(this.claimTitle)
    },
    tokenImage () {
      if (this.claimData) {
        return this.tokenImagePath(this.claimData.collection, this.claimData.type, 300)
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapMutations([
      'setPortfolioNeedsRefresh'
    ]),
    /**
     * CLAIMING STEP 2 - call the claimToken function
     * Attempts to claim (mint on demand) the target token, based on the provided sale / claim data.
     */
    async attemptClaim (claimData) {
      console.log(`Attempting claim for ${claimData.id}.`)
      this.claiming = true
      this.agreed = false
      try {
        await this.fbCall('claiming-claimToken', {
          claimId: claimData.claimId,
          ...(this.mintForUser && this.recipient) && { recipient: this.recipient }
        })
        this.addNotification('Finished minting token.', 'success', true)
        this.setPortfolioNeedsRefresh(true)
      } catch (err) {
        console.log(err)
        this.addNotification(err.message, 'error')
      }
      this.claiming = false
    }
  }
}
</script>

<style lang="scss" scoped>
.claim-card {
  position: relative;
  margin-bottom: $space-xl;
  text-align: center;
  font-size: 0; // Remove extra space

  &.claimed {
    display: none;
  }

  img {
    width: 100%;
  }
  .claim-card__media {
    position: relative;
  }
  .overlay {
    @include fill-parent;
    background-color: transparentize($color: #333, $amount: 0.33)
  }
  .claim-title {
    font-family: Untitled Sans;
    font-style: normal;
    font-weight: bold;
    font-size: $font-size-ml;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 16px;

    &.break {
      word-break: break-all;
    }

    @include desktop {
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 32px;
    }

    @include media($bp-phone-l) {
      font-size: $font-size-m;
    }
  }
  .claim-card__content {
    @include center-in-parent;
    width: 100%;
    padding: $space-ml;
    color: $blk-white;
    font-size: 12px;

    @include media($bp-phone-l) {
      padding: $space-s;
    }

    ::v-deep .v-input--checkbox {
      .v-input__control {
        align-items: center;
        .v-input__slot {
          width: auto;
        }
      }
    }
    @include media($bp-phone-l) {
      width: calc(100% - #{$space-s} * 2)
    }
    .content {
      margin-bottom: $space-m;
    }
  }
  .claim-button {
    color: #F4F4F4;
    border-color: $blk-white;
    width: 100%;
    max-width: 280px;
    border-radius: 4px;
    margin-top: 20px;
    font-family: Untitled Sans;
    font-style: normal;
    font-size: 18px;

    font-weight: bold;
    line-height: 120%;
    padding-top: 12px;
    padding-bottom: 12px;

    @include desktop {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 18px;
    }

    &:disabled {
      color: $blk-grey-2;
      background-color: transparentize($blk-grey-5, 0.5);
      border-color: $blk-grey-5;
    }
  }
}
</style>
