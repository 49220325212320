<!--
  Displays a single user's portfolio (owned, minted tokens)
-->
<template>
  <div class="portfolio-container">
    <div class="portfolio-header">
      <Typography variant="pc-title-48 desktop-nav-only">Portfolio</Typography>
      <div class="tabs">
        <div
          v-if="recentTotalTokenOwned > 0 && !isMobile"
          class="tabs__item"
          :class="{ active: calculatedViewing == 'justAdded' }"
          @click="viewing = 'justAdded'"
        >
          Just Added {{ recentTotalTokenOwned > 0 ? `(${recentTotalTokenOwned})` : '' }}
        </div>
        <div
          class="tabs__item"
          :class="{ active: calculatedViewing == 'collection' }"
          @click="viewing = 'collection'"
        >
          Collection {{ totalTokensOwned > 0 ? `(${totalTokensOwned})` : '' }}
        </div>
        <div
          class="tabs__item"
          :class="{ active: calculatedViewing == 'claims' }"
          @click="viewClaims"
        >
          Claims {{ unclaimed && unclaimed.length > 0 ? `(${unclaimed.length})` : '' }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div v-if="calculatedViewing == 'claims'" class="unclaimed-tokens standard-gallery">
        <template>
          <ClaimCard
            v-for="(claim, index) in userClaims"
            :key="`claim-${index}`"
            :claimData="claim"
            :claimed="claim.claimed"
            @finish="reloadPortfolio"
          />
        </template>
      </div>
      <div v-if="calculatedViewing == 'collection'" class="collection">
        <div
          v-if="singleCollectionPortfolio.length && totalTokensOwned > 0"
          class="token-galleries"
        >
          <div
            v-for="(collection, index) in singleCollectionPortfolio"
            :key="`portfolio-collection-${index}`"
          >
            <template v-if="collection.tokens && collection.tokens.length > 0">
              <!-- <h3 v-if="collection.name" class="collection-header">{{ collection.name }}</h3> -->
              <div class="standard-gallery">
                <TokenCard
                  v-for="(token, index) in collection.tokens"
                  :key="`portfolio-token-${index}`"
                  :tokenData="token"
                  mode="portfolio"
                  square
                  :listed="token.listed"
                  :index="index"
                  @view="
                    $router.push(
                      `/portfolio/${token.tokenContract.tokenAddress}/${token.id}`
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
        <p v-else-if="!loading" class="no-tokens-msg blk-caption">
          You have no nfts.<br />
          Visit the <router-link to="/market">market</router-link> to find
          opportunities and start your collection.
        </p>
      </div>
      <div v-if="calculatedViewing == 'justAdded'" class="just-added">
        <div
          v-if="singleCollectionPortfolio.length && recentTotalTokenOwned > 0"
          class="token-galleries"
        >
          <div
            v-for="(collection, index) in singleCollectionPortfolio"
            :key="`portfolio-collection-${index}`"
          >
            <template
              v-if="
                collection.recentTokens && collection.recentTokens.length > 0
              "
            >
              <!-- <h3 v-if="collection.name" class="collection-header">{{ collection.name }}</h3> -->
              <div class="standard-gallery">
                <TokenCard
                  v-for="(token, index) in collection.recentTokens"
                  :key="`portfolio-token-${index}`"
                  :tokenData="token"
                  mode="portfolio"
                  square
                  :listed="token.listed"
                  :index="index"
                  @view="
                    $router.push(
                      `/portfolio/${token.tokenContract.tokenAddress}/${token.id}`
                    )
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import ClaimCard from '@/components/ClaimCard'
import { doc, updateDoc, arrayRemove } from 'firebase/firestore'
export default {
  components: {
    ClaimCard
  },
  async created () {
    this.viewing = this.$route.query.viewing || 'collection'
    this.setLoading(true)

    // Mark loading and get all token contracts
    try {
      this.setGlobalState({
        target: 'loadingStatus',
        val: 'Loading collections on market...'
      })
      await this.getTokenContracts()
    } catch (err) {
      this.addNotification('Error retrieiving collections on market.', 'error')
    }

    try {
      this.clearOldPurchases()
    } catch (err) {
      console.log('Error modifying recent purchases array.')
    }

    // Get the user's owned tokens on load
    try {
      this.setGlobalState({
        target: 'loadingStatus',
        val: 'Getting your tokens...'
      })
      await this.getOwnedTokens()
    } catch (err) {
      console.log(err)
      this.addNotification('Error getting owned tokens', 'error')
    }

    // Get all of the current user's listed tokens.
    try {
      this.setGlobalState({
        target: 'loadingStatus',
        val: 'Getting listings...'
      })
      this.setGlobalState({ target: 'unobtrusiveLoader', val: true })
      const sales = await this.fbCall('getSalesBySeller', {
        seller: this.userProfile.nearId,
        marketContract: this.globalSettings
          ? this.globalSettings.marketContract
          : ''
      })
      // console.log('Sales', sales.data)
      this.sales = sales.data
    } catch (err) {
      console.log(err)
      this.addNotification('Error getting sales data.', 'error')
    }

    // Clear loaders
    this.setGlobalState({ target: 'unobtrusiveLoader', val: false })
    this.setLoading(false)
  },
  data () {
    return {
      viewing: 'collection', // 'collection' or 'claims'
      portfolio: [],
      sales: []
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'userProfile',
      'userClaims',
      'unclaimed',
      'globalSettings',
      'loading',
      'tokenContracts',
      'portfolioNeedsRefresh'
    ]),
    // Format the token data appropriately for the TokenCard
    portfolioFormatted () {
      if (!this.sales) {
        return this.portfolio
      }
      if (!this.portfolio) return []
      const portfolioFormatted = this.portfolio
      const recentPurchases = this.userProfile && this.userProfile.recentPurchases ? this.userProfile.recentPurchases : []
      portfolioFormatted.forEach((collection) => {
        if (collection.tokens) {
          const items = []
          const recentItems = []
          collection.tokens.forEach((item) => {
            const metaDataExtra = JSON.parse(item.metadata.extra)
            item.title = item.metadata.title
            item.issuance = metaDataExtra?.cap || null
            item.secondaryType = this.getDisplayedSecondaryType(
              collection,
              item.id
            )
            this.sales.forEach((sale) => {
              if (item.id === sale.tokenId) {
                item.listed = true
                // console.log(`${item.id} is listed`)
              }
            })
            items.push(item)

            if (recentPurchases.some((purchase) => purchase.id === item.id)) {
              recentItems.push(item)
            } else {
              const { days } = moment.duration({
                from: moment.unix(item.metadata.issued_at / 1000),
                to: new Date()
              })._data
              if (days < 1) {
                recentItems.push(item)
              }
            }
          })
          collection.tokens = items
          collection.recentTokens = recentItems
        }
      })
      return portfolioFormatted
    },

    /**
     * Concats all the separate collection objects into a single collection object for display in portfolio.
     * Use if you don't want to visually group collections in the portfolio.
     */
    singleCollectionPortfolio () {
      if (!this.portfolioFormatted) return []
      const single = [{ tokens: [], recentTokens: [] }]
      this.portfolioFormatted.forEach((collection) => {
        if (collection.tokens) {
          single[0].tokens = single[0].tokens.concat(collection.tokens)
          single[0].recentTokens = single[0].recentTokens.concat(collection.recentTokens)
        }
      })
      return single
    },
    totalTokensOwned () {
      let total = 0
      this.portfolio.forEach((collection) => {
        total += (collection.tokens || []).length
      })
      return total
    },
    recentTotalTokenOwned () {
      let total = 0
      this.portfolioFormatted.forEach((collection) => {
        total += (collection.recentTokens || []).length
      })
      return total
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    calculatedViewing () {
      if (this.isMobile && this.viewing === 'justAdded') {
        return 'collection'
      }
      return this.viewing
    }
  },

  watch: {
    portfolioNeedsRefresh: function (val) {
      if (val) {
        // When refreshing after claiming a token, view "justAdded"
        this.viewing = 'justAdded'
        this.reloadPortfolio()
      }
    }
  },

  methods: {
    ...mapMutations([
      'setLoading',
      'setGlobalState',
      'setPortfolioNeedsRefresh'
    ]),
    ...mapActions([
      'getTokenContracts'
    ]),

    /**
     * When viewing the portfolio, check the user's "recentPurchases"
     * If any are older than the threshold set below, remove them from the recentPurchases array
     */
    async clearOldPurchases () {
      const oldPurchaseThreshold = 1 // 1 day
      const recents = this.userProfile.recentPurchases || []
      // Check the age of each purchase
      recents.forEach((purchase) => {
        const purchaseTime = purchase.purchasedAt.seconds
        const { days, minutes } = moment.duration({
          from: moment.unix(purchaseTime),
          to: new Date()
        })._data
        console.log(`Purchased ${days} days, ${minutes} minutes ago.`)

        // If the purchase time ago (in minutes) is older than the threshold (in minutes), remove it from the array
        if (days >= oldPurchaseThreshold) {
          console.log('This is no longer a recent purchase.')
          updateDoc(doc(this.$fb.db, 'users', this.currentUser.uid), {
            recentPurchases: arrayRemove(purchase)
          })
        }
      })
    },

    getDisplayedSecondaryType (collection, id) {
      let secondaryType = id.split('.')[1] || ''
      if (!collection.variants || !collection.variants.secondary) {
        return secondaryType
      }
      for (let i = 0; i < collection.variants.secondary.length; i++) {
        const variantData = collection.variants.secondary[i]
        if (variantData.id === secondaryType) {
          secondaryType = variantData.name
        }
      }
      return secondaryType
    },

    async reloadPortfolio () {
      // CLAIMING STEP 5 - Reload user portfolio
      console.log('Reloading portfolio...')
      this.setLoading(true)
      await this.getOwnedTokens()
      this.setPortfolioNeedsRefresh(false)
      this.setLoading(false)
    },

    /**
     * Get any tokens the user owns.
     * Also includes an option to only display tokens in a target collection (based on .env variables)
     */
    async getOwnedTokens () {
      this.portfolio = []
      // Get all owned tokens for each collection on the market.
      for (const contract of this.tokenContracts) {
        const collection = {
          name: contract.name,
          variants: contract.variants,
          tokens: []
        }
        try {
          const owned = await this.fbCall('getOwnedByCollection', {
            owner: this.userProfile.nearId,
            collection: contract.id
          })
          if (owned.data) {
            collection.tokens = owned.data
          }
          // console.log('Collection data:', collection)
        } catch (err) {
          this.addNotification(
            `Sorry, there was an error retrieving your tokens in collection ${contract.id}.`,
            'error'
          )
        }
        this.portfolio.push(collection)
      }
    },

    viewClaims () {
      this.viewing = 'claims'
      // If the user clicks on claims, this marker should be checked so they don't see it after they leave.
      window.sessionStorage.setItem('shownHasClaims', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.token-galleries {
  .collection-header {
    font-size: $font-size-m;
    margin-bottom: $space-m;
  }
}
.portfolio-container {
  position: relative;

  @include desktop {
    margin-top: 0;
  }
}
.portfolio-header {
  display: flex;
  align-items: center;
  margin-bottom: $space-l;
  .tabs {
    flex-grow: 1;
    text-transform: uppercase;
    display: flex;

    @include desktop {
      justify-content: flex-end;
    }

    .tabs__item {
      cursor: pointer;
      padding-bottom: 5px;

      font-family: Untitled Sans;
      font-style: normal;
      font-size: $font-size-m;

      @include desktop {
        font-weight: bold;
        line-height: 130%;
        margin-left: $space-l;
      }

      @include mobile {
        margin-right: 18px;
        font-weight: bold;
        line-height: 120%;
        color: #666666;
      }

      &.active {
        border-bottom: solid 4px white;
        color: white;
      }
    }
  }
}
.bottom {
  position: relative;
}
.no-tokens-msg {
  text-align: center;
  padding: $space-xxl;
  color: $blk-grey-4;
  max-width: 650px;
  margin: auto;
  a {
    color: $blk-grey-4;
  }

  @include media($bp-phone-l) {
    padding: 20px;
  }
}
</style>
